<template>
  <div class="ntf-card" v-if="notification">
    <div class="ntf-card-content" @click="linkTo" :class="`${this.notification.linkTo ? 'pointer' : ''}`">
      <div class="ntf-card-left">
        <div class="ntf-card-icon">
          <UserAvatar :user="notification.user" />
        </div>
        <div class="ntf-card-info">
          <div class="ntf-card-info-content">
            <div class="ntf-card-info-label skeleton">{{ notification.title }}</div>
            <div class="ntf-card-info-user skeleton" v-html="`@${notification.user && notification.user.user}`"></div>
            <div class="ntf-card-info-desc skeleton" v-html="notification.message"></div>
          </div>
        </div>
      </div>
      <div class="ntf-card-right">
        <span class="tx_label">{{ getTimeLapse(notification) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["notification"],
  components: {
    ProgressBar: () => import("../widgets/ProgressBar.vue"),
  },
  methods: {
    linkTo: function() {
      if (!this.notification.linkTo) return;
      this.$router.push(this.notification.linkTo);
    },
  },
};
</script>

<style lang="scss">
.ntf-card {
  position: relative;
  margin: 0 0 $mpadding 0;
  &-content {
    padding: $mpadding;
    display: flex;
    width: 100%;
    align-items: center;
    background-color: #fff;
    @include shadow;
    border-radius: $mpadding/2;
  }
  &-left {
    flex: 1;
    display: flex;
    gap: $mpadding;
  }
  &-info {
    display: flex;
    align-items: center;
    &-label {
      font-weight: bold;
    }
    &-user {
      opacity: 0.7;
      margin: 0 0 $mpadding/2 0;
    }
    &-desc {
      opacity: 0.8;
      margin: $mpadding/4 0 0 0;
      font-weight: bold;
      font-size: 90%;
      background-color: #e6effc;
      padding: $mpadding;
      border-radius: 0 $mpadding $mpadding $mpadding;
    }
    .progress-bar {
      margin-top: $mpadding/2;
    }
  }
  &-right {
    text-align: right;
    align-self: flex-start;
    .tx_label {
      font-size: 85%;
      opacity: 0.7;
    }
  }
}
</style>
